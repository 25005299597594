<template>
  <main class="Financial">
    <TheSubHeader
      ref="theSubHeader"   
      icon="money"
      title="Financeiro"
      subtitle="Consolidado Financeiro"
      page-title-tag="Financial Consolidated"
      type="routerTabs"
      organizer-gap="1rem"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.financialOnlineErrors && permissions.financialOnlineErrors.isVisible"
          icon="exclamation-alt"
          color="primary-base"
          label="Erros Consolidado Online"
          :track="trackSubheaderButtonString('Financial Consolidated', 'Financial Consolidated Errors')"
          @click="goToOnlineErrors()"
        />
        <BaseButton
          v-if="permissions && permissions.editVehicles && permissions.editVehicles.isVisible"
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="trackSubheaderButtonString('Financial Consolidated', 'Financial Edit Vehicle')"
          @click="goToEditVehicle()"
        />
        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Consolidated', 'Operation Cost')"
          @click="openModalOperation()"
        />
      </template>
      <template #filter>
        <section
          class="Financial__subheaderTab Financial__subheaderTab--consolidated"
        >     
          <!-- Date filter -->
          <BaseDatePicker
            v-model="formattedDateRange"
            label="Intervalo entre datas (max. 15 dias)"
            :max-range="15"
            style="max-width: 280px;"
            placeholder="início ~ fim"      
            @input="handlerFinancialConsolidateRequest"
          />
          
          <!-- Status filter -->
          <BaseTreeSelect
            v-model="filters.status"
            name="Status"
            label="Status"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="statusList"
            style="max-width: 200px;"
            @input="handlerFinancialConsolidateRequest"
          />
        </section>
      </template>
    </TheSubHeader>

    <section class="Financial__content">
      <DataTable
        :header="financialConsolidate.data.headers"
        :table="financialConsolidate.data.content"
        @download="submitDownload"
        @seeErrors="seeErrors"
        @accessFortnight="goToFortnight"
      />

      <Pagination
        @page="fetchFinancialConsolidated"
        @paginate="fetchFinancialConsolidated"
      />

      <DownloadSpreadsheetModal ref="DownloadSpreadsheet" page="fortnight" />
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
//Components
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseDatePicker, BaseTreeSelect } from '@/components/atoms'
import { Pagination, DataTable } from '@/components/molecules'
import DownloadSpreadsheetModal from '@/views/financial/DownloadSpreadsheetModal'
//Mixins
import FinancialMixin from '@/mixins/financial'

export default {
  name: 'FinancialConsolidated',
  components: {
    TheSubHeader,
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    Pagination,
    DataTable,
    DownloadSpreadsheetModal,
  },

  mixins:[FinancialMixin],
  
  data() {
    return {
      //Filters
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        status: null,
      },
      reportsTable: {
        headers: {
          id: { id: 'id', isVisible: false, label: 'ID', param: 'id' },
          name: { id: 'name', isVisible: true, label: 'Planilhas', param: 'name' },
          type: { id: 'type', isVisible: true, label: 'Tipo de operação', param: 'type'},
          actions: { actions: true, id: 'actions', isVisible: true, label: '', param: 'actions' },
        },
        content: [
          { id: 1, name: 'Pré Consolidado Financeiro', type: 'online/offline', actions: [ { action_id: 'download', has_permission: true  } ] },
          { id: 2, name: 'Pré Consolidado por diárias', type: 'online', actions: [ { action_id: 'download', has_permission: true } ] },
          { id: 0, name: 'Consolidado financeiro', type: 'online/offline', actions: [ { action_id: 'download', has_permission: true  } ] },
        ],
      },
    }
  },

  computed: {
    ...mapState({
      permissions: state => state.auth.permissions,
      paginate: state => state.pagination.paginate,
      page: state => state.pagination.page,
      financialConsolidate: state => state.financial.financialConsolidate
    }),
  },

  mounted() {
    this.clearPagination(20)
    this.initStatusList()
    this.fetchFinancialConsolidated()
  },

  methods: {
    ...mapActions({
      clearPagination: 'pagination/clearPagination',
      setLoading: 'loading/setLoading',
      setPagination: 'pagination/setPagination',
      fetchFinancialConsolidatedHistory: 'financial/fetchFinancialConsolidatedHistory'
    }),

    handlerFinancialConsolidateRequest() {
      this.clearPagination(20).then(() => this.fetchFinancialConsolidated())
    },

    goToOnlineErrors() {
      this.$router.push({ name: 'financialOnlineErrors' })
    },

    async fetchFinancialConsolidated() {
      let payload = {
        page: this.page,
        paginate: this.paginate,
        filters: {
          status_id: this.filters?.status,
          date_start: this.filters?.dateRange?.init,
          date_end: this.filters?.dateRange?.end,
        }
      }

      await this.fetchFinancialConsolidatedHistory(payload, (r) => r, (e) => e, () => this.setLoading(false))
      this.setPagination(this.financialConsolidate)
    },

    goToFortnight(line) {
      this.$router.push(`fortnight/${line.id}`)
    },

    async submitDownload(data) {
      this.$refs.DownloadSpreadsheet?.open(this.reportsTable, data.id)
    },

    seeErrors() {
      this.$router.push({name: 'financialOnlineErrors'})
    }
  }
}
</script>

<style lang="scss" scoped>
.Financial {
  &__subheaderTab {
    display: flex;
    align-items: flex-end;
    grid-gap: 1rem;
      @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    
    &--consolidated {
      /deep/.vue-treeselect__control {
        margin-top: 8px;
      }

      /deep/.multiselect {
        margin-top: 10px;
      }

      .BaseDatePicker {
        width: 33%;
      }
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    padding: 0 0 3rem 0;
    
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }
}
</style>